<template>
<div class="content-info">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />

    <TheNav :navLinkList="navLinkList" :classifyList="classifyList" :isJump="isJump" />

    <div class="product-info">
      <div class="cate-title size-18">{{ cateTitle }}</div>
      <div class="text-doru"></div>
      <div class="data-list">
        <div class="data-item" v-for="(item,key) in productList" :key="key" @mouseenter="onHover(key)" @mouseleave="leaveHover()">
            <router-link :to="{ name: 'ProductDetail', params: { productId: item.id }}">
              <div class="item-title size-12 bold-title">{{ item.productName }}</div>
                  <img  :alt="item.productName" :title="item.productName" :src="item.productImgUrl" class="item-img">
              <div :class="isHover==key ? 'item-right-hover' : 'item-right'"></div>
            </router-link>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { getList } from "@/api/product/index";
import { getProductNavList } from "@/utils/common";

export default {
  name: 'ProductList',
  props: {
  },
  data() {
    return {
      isHover:'',
      classifyList:[],
      navLinkList:[],
      isJump:true,
      productList:[],
      cateTitle:'',
      show:false,
      showText:'',
      classifyId:0
    }
  },
   watch: {
	// 监听路由是否变化
	'$route' (to) { 
      if(to.params.productId != this.classifyId){
        this.classifyId = parseInt( this.$route.params.productId) || 0
        this.init(); // 重新调用加载数据方法
      }
    },
  },
  created() {
    // this.classifyId = parseInt( this.$route.query.productId) || 0
    let arr = this.$route.fullPath.split('/')
    this.classifyId = arr[3] || 0
    this.init(); 
  },
  methods: {
    init() {
      this.getProductList()
    },
     onHover(index) {
      this.isHover = index;
    },
    leaveHover() {
      this.isHover = -1;
    },
    getProductList() {
      this.show = true;
      this.showText = "加载中...";
      this.classifyList = []
      this.navLinkList = getProductNavList()
      getList(this.proParams)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            if(res.data.length > 0) {
              let info 
              res.data.forEach((item) =>  {
                let id = item.id
                info = {
                  id:item.id,
                  name: item.typeName,
                  productList:item.productList,
                  link:{name:'ProductListParams',params:{productId:id}},
                }
                this.classifyList.push(info)
              })

              let cate = []
               cate = this.classifyList.find(item => {return item.id == this.classifyId})
               if(!cate || cate.length == 0) {
                let id = this.classifyId
                if(id) {
                  this.classifyList[0].link = {name:'ProductListParams',params:{productId:id}}
                }else {
                  this.classifyList[0].link = {name:'ProductList'}
                }
                cate = this.classifyList[0]
               }
              // this.newsList = cate.newsList
              this.productList = cate.productList
              this.cateTitle = cate.name
              this.navLinkList.push({name:this.cateTitle,link:''})
            }
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    },
    // changeNav(index) {
    //   this.productList = this.classifyList[index].productList
    //   this.cateTitle = this.classifyList[index].name
    // }
  }
}
</script>

<style scoped>

  .product-info {
    padding:20px 10%;
  }

  .product-info .cate-title {
    margin-bottom:10px;
    text-align:center;
  }

  .data-item {
    display: inline-block;
    margin:0px 2%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:21%;
    height:0;
    padding-top:14%;
    margin-top:60px;
    cursor: pointer;
    /* margin-right:12rpx; */
  }

  .data-item a {
    color: #525252;
  }

  .data-item a:hover{
    color: #009dff;
  }

 .data-item .item-img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
}

 .data-item .item-title {
    font-size:14px;
    text-align: center;
    position: absolute;
    top:-40px;
    width: 100%;
}

.item-right {
  display: block;
    width: 26px;
    height: 26px;
    border-radius: 60px;
    border: 2px #525252 solid;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-top:20px;
}

.item-right:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    margin: -4px 0 0 0;
    width: 10px;
    height: 10px;
    border-top: 2px solid #525252;
    border-right: 2px solid #525252;
    transform: rotate(45deg);
    transition: left 0.6s;
}

.item-right-hover {
  display: block;
    width: 26px;
    height: 26px;
    border-radius: 60px;
    border: 2px #009dff solid;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-top:20px;
    background-color: #009dff;
}

.item-right-hover:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    margin: -4px 0 0 0;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    transition: left 0.6s;
}

@media (max-width: 1360px) {
    .data-item {
      width:29.3%;
      padding-top:19.5%;
      /* margin-right:12rpx; */
    }
}

@media (max-width: 768px) {
    .data-item {
      width:46%;
      padding-top:30.7%;
      /* margin-right:12rpx; */
    }

    .product-info {
      padding:10px;
    }
}
</style>